<template>
  <div>
    <b-button
      v-if="!noBtn"
      variant="success"
      @click="toggleModal()"
    >
      <feather-icon
        icon="PlusIcon"
      />
      {{ $t('credits.direct_deposit') }}
    </b-button>
    <b-modal
      v-model="isDirectDepositModalActive"
      :title="`${$t('credits.direct_deposit')}`"
      hide-footer
    >
      <validation-observer
        ref="depositForm"
      >
        <form @submit.prevent="onSubmit">
          <validation-provider
            v-slot="{ errors }"
            :name="$t('credits.credit_amount')"
            rules="required|min_value:1"
          >
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="3"
              :label="`${$t('credits.credit_amount')} *`"
              label-for="amount"
            >
              <b-form-input
                id="amount"
                v-model="transferForm.amount"
                type="number"
                :state="errors[0] ? false : null"
              />
            </b-form-group>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            :name="$t('fields.remark')"
            rules="required"
          >
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="3"
              :label="`${$t('fields.remark')} *`"
              label-for="detail"
            >
              <b-form-textarea
                id="detail"
                v-model="transferForm.detail"
                :state="errors[0] ? false : null"
              />
            </b-form-group>
          </validation-provider>
          <div class="text-right">
            <b-button
              variant="light"
              class="mr-2"
              @click="onCloseModal"
            >
              {{ $t('buttons.cancel') }}
            </b-button>
            <b-overlay
              :show="isDepositting"
              rounded
              spinner-small
              class="d-inline-block"
            >
              <b-button
                type="submit"
                variant="primary"
                block
              >
                {{ $t('buttons.confirm') }}
              </b-button>
            </b-overlay>
          </div>
        </form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { required } from '@validations'
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    noBtn: {
      type: Boolean,
      default: false,
    },
    agentId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,

      isDirectDepositModalActive: false,
      transferForm: {
        amount: 0,
        detail: '',
      },
    }
  },
  computed: {
    ...mapState({
      isDepositting: (state) => state.agent.isDepositingAgentCredit,
    }),
  },
  created() {
    this.setData()
  },
  methods: {
    ...mapActions(['depositAgentCredit']),
    setData() {
      this.transferForm = {
        amount: 0,
        detail: '',
      }
    },
    toggleModal() {
      this.isDirectDepositModalActive = !this.isDirectDepositModalActive
    },
    onCloseModal() {
      this.isDirectDepositModalActive = false
      this.setData()
    },
   async onSubmit() {
      this.$refs.depositForm.validate().then( async (success) => {
        if (success) {
         this.transferForm.amount = +this.transferForm.amount
          await this.depositAgentCredit({
            agentId: this.agentId,
            data: this.transferForm,
          })
          this.onCloseModal()
        }
      })
      
    },
  },
}
</script>